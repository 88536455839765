import QrScanner from "qr-scanner";
import { useEffect, useRef, useState } from "react";
import { CircularProgress, Fade, IconButton, Stack, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import { FlashlightOn } from "@mui/icons-material";

let qrScanner: QrScanner | null = null;

export default function QrScannerComp({ onScanQrCode }: { onScanQrCode: (qrCode: string) => void }) {
  const videoElement = useRef<HTMLVideoElement>(null);
  const [qrScannerState, setQrScannerState] = useState<null | "started" | "stopped">(null);
  const lastResult = useRef<string>("");
  const [hasFlashlight, setHasFlashlight] = useState(false);

  const [videoElementRect, setVideoElementRect] = useState<DOMRect | null>(null);

  async function startQrScanner() {
    console.log("STARTING");
    if (qrScanner == null) {
      if (videoElement.current) {
        // setTimeout(() => {
        qrScanner = new QrScanner(
          videoElement.current,
          (result) => {
            if (result.data) {
              if (lastResult.current != result.data) {
                onScanQrCode(result.data);
              }
              lastResult.current = result.data;
            }
          },
          {
            preferredCamera: "environment",
            maxScansPerSecond: 10,
            highlightScanRegion: true,
            highlightCodeOutline: true,
            // onDecodeError: () => {
            //   lastResult.current = null;
            // },
          }
        );

        qrScanner.start().then(() => {
          console.log("STARTED");
        });

        qrScanner.hasFlash().then((result) => {
          if (result && qrScanner) {
            setHasFlashlight(true);
          }
        });
        // }, 1);
      }
    }
  }
  function stopScanner() {
    if (qrScanner) {
      qrScanner.stop();
      setQrScannerState("stopped");
      qrScanner = null;
    }
  }

  useEffect(() => {
    startQrScanner();

    return () => {
      stopScanner();
    };
  }, []);

  useEffect(() => {
    let interval = 0;
    if (qrScannerState == null) {
      interval = window.setInterval(() => {
        if (videoElement.current) {
          if (videoElement.current.isConnected) {
            setQrScannerState("started");

            setTimeout(() => {
              setVideoElementRect(videoElement.current?.getBoundingClientRect() ?? null);
            }, 800);
          }
        }
      }, 300);
    }
    return () => {
      clearInterval(interval);
    };
  }, [qrScannerState]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: 1,
        width: 1,

        position: "relative",
      }}
    >
      <Stack direction={"row"} gap={2} alignItems={"center"}>
        <Fade in={qrScannerState == "started"}>
          <Typography sx={{ fontSize: "1.1rem", fontWeight: 800 }}>Scanning QR Code</Typography>
        </Fade>

        <CircularProgress />
      </Stack>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: 1,
          width: 1,

          position: "relative",
        }}
      >
        <Box
          sx={{
            position: "fixed",
            top: (videoElementRect?.top ?? 0) + 10,
            right: (videoElementRect?.left ?? 0) + 10,
            zIndex: 10,
          }}
        >
          <Fade in={Boolean(hasFlashlight && videoElementRect)}>
            <IconButton
              sx={{
                background: "black",
              }}
              onClick={() => {
                qrScanner?.turnFlashOn();
              }}
            >
              <FlashlightOn />
            </IconButton>
          </Fade>
        </Box>

        <video style={{ maxWidth: "100%", maxHeight: "100%" }} ref={videoElement}></video>
      </Box>
    </Box>
  );
}
