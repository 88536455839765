import { alpha, Box, Paper, Typography, useTheme } from "@mui/material";
import Login from "./components/Login";

import Hexagon from "../assets/images/Hexagon.svg";
import Logo from "../assets/images/Logo.svg";
import { useAppSelector } from "../global/utils/redux/store";
import FormattedMessageJamezz from "../global/components/FormattedMessageJamezz";
import { useCallback, useEffect, useState } from "react";
import useSession from "./useSession.tsx";

export default function Main() {
  const theme = useTheme();
  const sessionState = useAppSelector((state) => state.global.sessionState);

  const [data, setData] = useState<any>(null);

  const fetchData = useCallback(async (salesareaId: number) => {
    const response = await fetch(`v5_2/login/getSalesareaInfo/${salesareaId}`);
    if (!response.ok) {
      console.error("Error fetching data:", response);
    } else {
      const jsonData = await response.json();
      setData(jsonData);
    }
  }, []);

  useSession();

  useEffect(() => {
    if (sessionState.vestiging_id) {
      fetchData(sessionState.vestiging_id);
    }
  }, [sessionState.vestiging_id, fetchData]);

  return (
    <Box
      sx={{
        width: 1,
        height: 1,
        backgroundColor: "primary.light",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "flex-end",
        overflow: "hidden",
      }}
    >
      <Box sx={{ display: { xs: "none", sm: "block" }, position: "absolute", width: 1, height: 1 }}>
        <img
          srcSet={data?.data.background.srcset}
          src={"https://jamezz.blob.core.windows.net/jamezz/appv5/assets/images/MainPhoto.webp"}
          alt="background"
          style={{ position: "absolute", width: "100%", height: "100%", objectFit: "cover", zIndex: 0 }}
        />
      </Box>
      <Box sx={{ display: { xs: "block", sm: "none" }, position: "absolute", width: 1, height: "450px", top: 0 }}>
        <img
          srcSet={data?.data.background.srcset}
          src={"https://jamezz.blob.core.windows.net/jamezz/appv5/assets/images/MainPhoto.webp"}
          alt="background"
          style={{ position: "absolute", width: "100%", height: "100%", objectFit: "cover", zIndex: 0 }}
        />
      </Box>

      <Paper
        sx={{
          borderRadius: 8,
          marginTop: -4,
          marginBottom: -6,
          paddingBottom: 6,
          flex: "1 1 0%",
          zIndex: 5,
          maxHeight: 600,
          position: "relative",
        }}
      >
        <img
          srcSet={data?.data.logo.srcset}
          src={Logo}
          alt="logo"
          style={{ maxWidth: 100, position: "absolute", zIndex: 10, top: -50, left: "calc(50% - 50px)" }}
        />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            maxWidth: 600,
            padding: 2,
            height: 1,
            position: "relative",
            overflow: "hidden",
          }}
        >
          <img
            src={Hexagon}
            alt="hexagon"
            style={{ width: 1400, position: "absolute", bottom: 0, maxHeight: 450, opacity: 0.6 }}
          />
          <Box
            display={"flex"}
            flexDirection={"column"}
            alignItems={"center"}
            flex={1}
            paddingTop={4}
            paddingBottom={4}
            zIndex={10}
            paddingLeft={8}
            paddingRight={8}
            maxWidth={400}
          >
            <Typography variant={"h6"} align={"center"} sx={{ marginTop: 4 }}>
              <FormattedMessageJamezz id={"MEET JAMEZZ AND ORDER BY YOURSELF!"} />
            </Typography>
            <Typography variant={"subtitle2"} align={"center"} sx={{ marginTop: 1, opacity: 0.7 }}>
              <FormattedMessageJamezz id={"Enter the webcode below as stated on the sign."} />
            </Typography>
          </Box>

          <Paper
            sx={{
              margin: 2,
              backgroundColor: alpha(theme.palette.grey["800"], 0.6),
              borderRadius: 8,
              zIndex: 10,
              padding: 5,
            }}
          >
            <Login />
          </Paper>
        </Box>
      </Paper>
    </Box>
  );
}
