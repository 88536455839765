import { useState } from "react";
import { Button, Dialog, DialogContent } from "@mui/material";
import CloseDialogButton from "../Dialog/CloseDialogButton.tsx";
import { QrCode2 } from "@mui/icons-material";
import FormattedMessageJamezz from "../../../global/components/FormattedMessageJamezz.tsx";
import QrScannerComp from "./QrScannerComp.tsx";

export default function QrCodeScanner({ onScanQrCode }: { onScanQrCode: (qrCode: string) => void }) {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Button
        onClick={() => {
          setOpen(true);
        }}
        variant={"contained"}
        startIcon={<QrCode2 />}
      >
        <FormattedMessageJamezz id={"Scan using camera"} />
      </Button>
      <Dialog open={open} fullScreen={true}>
        <DialogContent>
          <CloseDialogButton
            onClose={() => {
              setOpen(false);
            }}
          />

          <QrScannerComp onScanQrCode={onScanQrCode} />
        </DialogContent>
      </Dialog>
    </>
  );
}
