import InternetConnection from "../global/components/InternetConnection";
import { IntlProvider } from "react-intl";
import { createTheme, CssBaseline, ThemeProvider } from "@mui/material";
import { useMemo } from "react";
import Main from "./Main";
import DialogProvider from "../global/utils/dialog/DialogProvider";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { routes } from "../global/utils/config";
import Box from "@mui/material/Box";
import { BlockUserInput } from "@jamezz/react-components";

function App() {
  const theme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: "dark",
          primary: { main: "#f7844a", contrastText: "#ffffff" },
          // background: { default: "#f7844a", paper: "#f7844a" },
        },
        typography: {
          fontFamily: "'Poppins', 'Roboto', 'Helvetica', 'Arial', 'sans-serif'",
        },
        components: {
          MuiTextField: {
            styleOverrides: {
              root: {
                borderTopRightRadius: 16,
                borderTopLeftRadius: 16,
              },
            },
          },
          MuiFilledInput: {
            styleOverrides: {
              root: {
                borderTopRightRadius: 16,
                borderTopLeftRadius: 16,
              },
            },
          },
          MuiButton: {
            styleOverrides: {
              root: {
                letterSpacing: 3,
                fontWeight: 1000,
              },
            },
          },
          MuiTypography: {
            styleOverrides: {
              h6: { fontWeight: 1000 },
            },
          },
        },
      }),
    []
  );

  return (
    <Box sx={{ width: 1, height: 1 }}>
      <IntlProvider
        locale={navigator.language}
        messages={{}}
        onError={() => {
          // console.log("error translation")
        }}
      >
        <BrowserRouter>
          <ThemeProvider theme={theme}>
            <CssBaseline enableColorScheme />

            <DialogProvider>
              <InternetConnection />

              <Routes>
                <Route path={routes.jamezz.rootPath} element={<Main />} />
              </Routes>

              <BlockUserInput.View />
            </DialogProvider>
            <ToastContainer
              theme={"colored"}
              position={"bottom-right"}
              autoClose={5000}
              newestOnTop={false}
              closeOnClick
              pauseOnFocusLoss
              draggable
              pauseOnHover
            />
          </ThemeProvider>
        </BrowserRouter>
      </IntlProvider>
    </Box>
  );
}

export default App;
