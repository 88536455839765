import { useEffect } from "react";
import { httpPost } from "../global/utils/api/http";
import JamezzApi from "../global/utils/api/JamezzApi";
import { setSessionState } from "../global/utils/redux/globalSlice";
import { useAppDispatch, useAppSelector } from "../global/utils/redux/store";

export default function useSession() {
  const sessionState = useAppSelector((state) => state.global.sessionState);
  const dispatch = useAppDispatch();

  useEffect(() => {
    const webcode = sessionStorage.getItem("jamezz_login_webcode");
    if (webcode) {
      const searchParams = new URLSearchParams();
      searchParams.set("webcode", webcode);
      searchParams.set("returnUrl", "/return");
      httpPost(
        JamezzApi.get.getSessionState.url + "?" + searchParams.toString(),
        {},
        { showSpinner: true, showToastError: false, showToastSuccess: false }
      ).then((response: any) => {
        dispatch(setSessionState(response.data.data));
      });
    }
  }, []);
}
